import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		padding: 28px 0 0;
		flex-wrap: wrap;
	}
	& .BHFooterMenus {
		order: 3;
		padding: 0;

		${({ theme }) => theme.breakpoints.up('md')} {
			order: 2;
			border-top: none;
			padding-left: calc(var(--spacing) * 2.75);
			margin-left: auto;
			margin-right: 0;
			max-width: 60%;
			gap: 20px;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			border: none;
			margin-bottom: 0;
			border-top: 1px solid var(--color-grey100);
			&:first-of-type {
				border-top: 0;
			}
			&:last-of-type {
				border-bottom: 1px solid var(--color-grey100);
			}
			${({ theme }) => theme.breakpoints.up('md')} {
				border-top: 0;
				&:last-of-type {
					border-bottom: 0;
				}
			}

			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};

				.MuiTypography-root {
					line-height: 40px;
					font-size: ${({ theme }) => theme.typography.pxToRem(24)};
					${({ theme }) => theme.breakpoints.up('md')} {
						font-size: ${({ theme }) => theme.typography.pxToRem(18)};
					}
				}
			}

			& .MuiAccordionSummary-root {
				padding: 15px calc(var(--spacing) * 2.5);
				${({ theme }) => theme.breakpoints.up('md')} {
					padding: 0;
				}
				.MuiSvgIcon-root {
					color: var(--color-primary);
				}
			}

			& .MuiAccordionDetails-root {
				padding: 0px calc(var(--spacing) * 2.5);
				.MuiLink-root {
					padding: 13px 0;
					line-height: 11px;
					font-size: ${({ theme }) => theme.typography.pxToRem(18)};
					letter-spacing: var(--letter-spacing-body);
					${({ theme }) => theme.breakpoints.up('md')} {
						font-size: var(--font-size-body1);
						margin: 0;
						letter-spacing: var(--letter-spacing);
					}
				}

				${({ theme }) => theme.breakpoints.up('md')} {
					padding: unset;
				}
			}
		}
	}
	& .BHNewsletter {
		padding: var(--spacing-9) calc(var(--spacing) * 2.5) 24px;
		${({ theme }) => theme.breakpoints.up('md')} {
			padding: 0;
		}
	}
	& .BHFooterSocialLinks {
		order: 2;
		width: 100%;
		flex-grow: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: var(--spacing-2);
		padding-left: 13px;
		padding-bottom: 32px;
		& .BHSocialWrapper {
			& a {
				margin: 0;
				padding: 5px;
				color: var(--color-primary);
			}
			.MuiSvgIcon-root {
				fill: none;
			}
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			padding-top: 50px;
			padding-left: 8px;

			order: 3;
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 100%;
	order: 1;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(0, 2)};
		max-width: 432px;
		width: 100%;
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
